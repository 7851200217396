<template>
  <div class="chatContainer">
    <div>
      <el-button
        @click="onConnect"
        v-if="!status"
        style="color: red; margin-top: 20px"
        >Team {{ group_id.split("|")[1] }} Chat Room Disconnected; Click and try
        to RE-Connect!</el-button
      >
      <el-button v-if="status" style="color: green; margin-top: 20px"
        >Team {{ group_id.split("|")[1] }} Chat Room Connected</el-button
      >
      <div v-for="(item, index) in teamStatus" :key="index">
        {{ item.name }}:{{ item.status === 1 ? "Online" : "Offline" }};
      </div>
    </div>
    <div
      style="
        flex: 1;
        display: flex;
        overflow: hidden;
        flex-direction: column;
        justify-content: center;
        margin-left: 20px;
        margin-right: 20px;
      "
    >
      <div
        style="
          flex: 1;
          overflow-y: scroll;
          padding-right: 15px;
          padding-bottom: 100px;
          box-sizing: border-box;
        "
        id="msgContainer"
      >
        <div v-for="(item, index) in messages" :key="index">
          <!--我接收的消息-->
          <div
            v-if="item.type == 1"
            style="
              padding-right: 15px;
              padding-left: 15px;
              display: flex;
              flex-direction: column;
              margin-bottom: 20px;
            "
          >
            <div
              style="
                text-align: left;
                position: relative;
                display: flex;
                flex-direction: row;
                align-items: flex-end;
              "
            >
              <div class="exam-chat-name">{{ item.name }}</div>
              <div class="exam-chat-time" style="margin-left: 10px">
                {{ formatShowDate(item.time) }}
              </div>
            </div>
            <div
              v-if="item.mode === 'txt'"
              style="margin-top: 15px; text-align: left"
            >
              {{ item.content }}
            </div>
            <div
              v-if="item.mode === 'audio'"
              style="margin-top: 15px; text-align: left; cursor: pointer"
              @click="playAudio(item)"
            >
              <img
                style="
                  border: 1px solid #ddd;
                  padding: 10px 60px 10px 10px;
                  border-radius: 6px;
                "
                src="../../assets/voiceplay.svg"
              />
            </div>
          </div>
          <!--我发送的消息-->
          <div
            v-if="item.type == 0"
            style="
              padding-right: 15px;
              padding-left: 15px;
              display: flex;
              flex-direction: column;
              margin-bottom: 20px;
            "
          >
            <div
              style="
                text-align: right;
                position: relative;
                display: flex;
                flex-direction: row-reverse;
                align-items: flex-end;
              "
            >
              <div class="exam-chat-name">{{ item.name }}</div>
              <div class="exam-chat-time" style="margin-right: 10px">
                {{ formatShowDate(item.time) }}
              </div>
            </div>
            <div
              v-if="item.mode === 'txt'"
              style="margin-top: 15px; text-align: right"
            >
              {{ item.content }}
            </div>
            <div
              v-if="item.mode === 'audio'"
              style="margin-top: 15px; text-align: right; cursor: pointer"
              @click="playAudio(item)"
            >
              <img
                src="../../assets/voiceplay.svg"
                style="
                  border: 1px solid #ddd;
                  padding: 10px 60px 10px 10px;
                  border-radius: 6px;
                "
              />
            </div>
          </div>
        </div>
      </div>
      <div
        style="
          margin-bottom: 10px;
          height: 50px;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;
          cursor: pointer;
        "
      >
        <img
          src="../../assets/voice.svg"
          @click="initVoice"
          style="width: 32px; height: 32px; margin-right: 16px; cursor: pointer"
        />

        <el-input
          v-if="!isVoice"
          v-model="input_chat_content"
          placeholder="Please input your message"
          @keyup.enter.native="sendNewMessage"
        ></el-input>
        <div
          v-if="isVoice"
          class="voiceInput"
          @mousedown="startRecord"
          @mouseup="endRecord"
        >
          按住 说话
        </div>
        <div>
          <div class="exam-chat-btn" @click="sendNewMessage">SEND</div>
        </div>
      </div>
    </div>
    <audio style="display: none" ref="audio"></audio>
    <!-- </el-drawer> -->
    <!-- 胡老师：这里应该定制成聊天的界面 -->
  </div>
</template>

<script>
import { getGroupId, getTeamMembersWithId } from "../../api/index";
import { getUserName, getProjectCode, getUserId } from "../../utils/store";
import { formatDate } from "../../utils/date";
export default {
  name: "text-chat",
  data() {
    return {
      showTeamChatDrawer: false,
      input_chat_content: "",
      door: false,
      status: this.value,
      ws: null,
      stream: null,
      messages: [],
      group_id: "",
      teamMembers: null,
      teamStatus: [],
      statusTimer: null,
      isVoice: false,
      recorder: null,
      templeAuidoRecord: null,
    };
  },
  props: {
    // group_id: {
    //   type: String
    // },

    watch: {
      value(e) {
        this.status = e;
      },
    },
    user_id: {
      type: String,
    },
    app_id: {
      type: String,
    },
    value: {
      type: Boolean,
    },
  },
  watch: {
    value(e) {
      this.status = e;
    },
  },
  created() {
    getGroupId(this.app_id).then((res) => {
      this.group_id = res.data;
      getTeamMembersWithId(getProjectCode(), getUserId()).then((res) => {
        this.teamMembers = res.data.data;
        this.onConnect();
      });
    });
  },
  methods: {
    playAudio(item) {
      let audio = this.$refs.audio;
      audio.pause();
      audio.src = item.content;
      audio.play();
    },
    formatShowDate(time) {
      var date = new Date(time);
      return formatDate(date, "hh:mm:ss");
    },
    sendNewMessage() {
      if (!this.status) {
        this.$message({
          message:
            "Chat Room Disconnected; Please re-connect before sending a message.",
          type: "warning",
          duration: 5000,
          showClose: true,
        });
        return;
      }
      let msg = this.input_chat_content;
      if (msg == undefined || msg == "") {
        this.$message({
          message: "Please input a message",
          type: "warning",
          duration: 5000,
          showClose: true,
        });
        return;
      }
      this.sendMessage(msg);
    },
    onConnect() {
      if (!this.group_id || !this.user_id) {
        this.$message({
          message: "Please input your user id and chat room id",
          type: "error",
          duration: 5000,
          showClose: true,
        });
        return;
      }

      //建立连接
      // let ws = new WebSocket("wss://106.14.173.221:9000");
      let ws = new WebSocket("wss://team-chat.learningfirst.cn:9000");
      ws.onerror = (err) => {
        console.log(err);
        // this.$message.error(err.)
        // this.$message.error('Server Error')
      };
      this.ws = ws;

      //对ws进行设置

      //成功建立的操作
      ws.onopen = () => {
        console.log("APP_ID:" + this.app_id);
        console.log("USER_ID:" + this.user_id);
        console.log("GROUP_ID:" + this.group_id);
        this.$message({
          message: "Connection Success",
          type: "success",
          duration: 5000,
          showClose: true,
        });
        this.changeStatus(true);
        ws.send(`user&${this.group_id}&${this.user_id}`);
        ws.send("getGroupConnection");
        this.statusTimer = setInterval(() => {
          ws.send("getGroupConnection");
        }, 5000);
        this.$emit("getWsOut", ws);
      };
      //关闭连接的操作
      ws.onclose = () => {
        this.changeStatus(false);
        this.$emit("getWsOut", null);
      };
      //接收到消息的操作
      ws.onmessage = (e) => {
        // new Response(e.data).arrayBuffer().then((buffer) => {
        //   console.log('buffer :' + buffer )
        // })
        if (typeof e.data === "string") {
          let data = e.data;
          //同步答案
          if (data.indexOf("answerSync") !== -1) {
            let obj = JSON.parse(data);
            this.$emit("answerSync", obj);
            //同步问题
          } else if (data.indexOf("correctQuestionSync") !== -1) {
            let obj = data.split("&")[1];
            obj = JSON.parse(obj);
            this.$emit("correctQuestionSync", obj);
            //同步图片
          } else if (data.indexOf("correctSessionPicSync") !== -1) {
            let obj = data.split("&")[1];
            obj = JSON.parse(obj);
            this.$emit("correctSessionPicSync", obj);
          } else if (data.indexOf("onlineStatus") !== -1) {
            this.handleTeamStatus(JSON.parse(data));
            //系统消息 直接弹出message
          } else {
            this.$message({
              message: "系统消息：" + e.data,
              duration: 10000,
              showClose: true,
            });
          }
        } else {
          var reader = new FileReader();
          reader.readAsText(e.data, "UTF-8");
          reader.onload = () => {
            try {
              let data = JSON.parse(reader.result);
              if (data.content === "AUDIOREADY") {
                this.templeAuidoRecord = null;
                this.templeAuidoRecord = {
                  type: 1,
                  mode: "audio",
                  time: data.time,
                  name: data.name,
                  content: data.content,
                };
              } else {
                let new_msg = {
                  type: 1,
                  mode: "txt",
                  time: data.time,
                  name: data.name,
                  content: data.content,
                };
                this.messages.push(new_msg);
                this.scrollToBottom();
              }
            } catch (err) {
              if (this.templeAuidoRecord) {
                this.templeAuidoRecord.content = URL.createObjectURL(e.data);
                this.messages.push(this.templeAuidoRecord);
                this.templeAuidoRecord = null;
                this.scrollToBottom();
              }
            }
          };
        }
      };

      this.getAudioStream();
    },
    startRecord() {
      console.log("开始录制");
      if (this.recorder) {
        if (!this.door) {
          this.recorder.start();
          this.door = true;
        }
      }
    },
    endRecord() {
      console.log("结束录制");
      if (this.recorder) {
        if (this.door) {
          this.recorder.stop();
          this.door = false;
        }
      }
    },
    getStringFromBlob(blob) {
      var reader = new FileReader();
      // const data = Blob {size:64,type:'',data:blob}
      let result = null;
      // if(data )

      reader.readAsText(blob, "UTF-8");
      reader.onload = function (e) {
        console.log("一定行！" + reader.result);
        result = reader.result;
      };
      console.log("返回结果！" + reader.result);
      return result;
      //
    },
    offConnect() {
      if (this.ws) {
        this.ws.close();
        this.changeStatus(false);
        if (this.stream && this.stream.getTracks) {
          this.stream.getTracks().forEach((track) => track.stop());
        }
      }
    },
    changeStatus(bool) {
      this.status = bool;
      this.$emit("input", bool);
    },
    sendMessage(msg) {
      let time = new Date().getTime();
      let value = {
        name: getUserName(),
        time: time,
        content: msg,
        mode: "txt",
      };
      var result = JSON.stringify(value);
      this.ws.send(result);
      value["type"] = 0;
      this.messages.push(value);
      console.log("Message sent");

      this.input_chat_content = "";

      this.scrollToBottom();
    },
    openDrawer() {
      this.showTeamChatDrawer = true;
    },
    scrollToBottom() {
      this.$nextTick(() => {
        var container = document.getElementById("msgContainer");
        container.scrollTop = container.scrollHeight;
      });
    },
    handleTeamStatus(data) {
      let statusMap = [];
      for (let i in this.teamMembers) {
        if (i !== "onlineStatus") {
          let obj = {
            name: this.teamMembers[i],
            status: data[i] ? data[i] : 0,
          };
          statusMap.push(obj);
        }
      }
      this.teamStatus = statusMap;
      console.log(this.teamStatus);
    },
    getAudioStream() {
      return new Promise((resolve) => {
        navigator.mediaDevices
          .getUserMedia({ audio: true, video: false })
          .then((stream) => {
            this.stream = stream;
            this.$message.success("Local voice caught successfully");
            let recorder = new MediaRecorder(stream);
            this.recorder = recorder;
            //语音录制好的操作
            recorder.ondataavailable = (e) => {
              //发音频讯号
              let time = new Date().getTime();
              let value = JSON.stringify({
                name: getUserName(),
                time: time,
                content: "AUDIOREADY",
              });
              this.ws.send(value);
              console.log("A voice message sent");
              this.ws.send(e.data);
              this.messages.push({
                type: 0,
                mode: "audio",
                time: time,
                name: getUserName(),
                content: URL.createObjectURL(e.data),
              });
            };

            resolve(true);
          })
          .catch((err) => {
            this.$message.error(err);
            resolve(false);
          });
      });
    },
    async initVoice() {
      if (!this.isVoice) {
        if (!this.stream || !this.recorder) {
          let result = await this.getAudioStream();
          if (result) {
            this.isVoice = !this.isVoice;
          }
        } else {
          this.isVoice = !this.isVoice;
        }
      } else {
        this.isVoice = !this.isVoice;
      }
    },
  },
  destroyed() {
    console.log("Socket Closed");
    this.offConnect();
    clearInterval(this.statusTimer);
  },
};
</script>

<style scoped lang="scss">
.chatContainer {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  height: 100%;
  .voiceInput {
    text-align: center;
    cursor: pointer;
    border: 2px solid #005c6b;
    color: #005c6b;
    flex: 1;
    border-radius: 2px;
    height: 32px;
    line-height: 32px;
  }
}
.exam-chat-name {
  color: #4f4f4f;
  font-size: 18px;
  font-weight: 300;
}
.exam-chat-time {
  color: #b9b9b9;
  font-size: 14px;
}

.exam-chat-content {
  color: #a6a6a6;
  font-size: 16px;
  line-height: 32px;
}

.exam-chat-btn {
  margin-left: 16px;
  cursor: pointer;
  border-color: #005c6b;
  border-style: solid;
  border-width: 2px;
  background-color: transparent;
  height: 32px;
  text-align: center;
  line-height: 32px;
  color: #005c6b;
  font-size: 16px;
  font-weight: bold;
  padding-right: 20px;
  padding-left: 20px;
}
</style>
